import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasicService } from '../../common/services/basic.service';
import { API_USERS_V2_URL } from '../../connection.data';

@Injectable()
export class UsersV2Service extends BasicService {
    constructor(http: HttpClient){
        super(http);
        this.baseUrl = API_USERS_V2_URL;
    }
}